/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import CustomCookieConsent from "./custom-cookie-consent/custom-cookie-consent";

import './../assets/styles/main.scss'

const LayoutSimple = ({ children }) => {
  return (
    <>
      <main id="site-wrapper">{children}</main>
      <CustomCookieConsent />
    </>
  )
}

LayoutSimple.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutSimple