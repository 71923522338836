import React from 'react'
import PropTypes from 'prop-types'
import './row.scss'

class Row extends React.Component {
  render() {
    const wrapClass = this.props.wrap ? 'row-wrap' : ''; 
    return(
      <div className={`row ${this.props.className} ${wrapClass}`}>
        {this.props.children}
      </div>
    ) 
  }
}

Row.defaultProps = {
  className: '',
  wrap: false
}

Row.propTypes = {
  className: PropTypes.string,
  wrap: PropTypes.bool,
}

export default Row