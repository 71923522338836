import React from 'react'
import './section.scss'

const Section = ({className, id, title, children}) => {
  return (
    <section id={id} className={'section ' + className}>
      <header>
        {title}
      </header>
      <div>
        {children}
      </div>
    </section>
  )
}

export default Section