import React from 'react'
import PropTypes from 'prop-types'
import './col.scss'

class Col extends React.Component {
  render() {
    const xsAttr = this.props.xs >= 0 ? { 'data-xs' : this.props.xs} : {}
    const xsOffsetAttr = this.props.xsOffset >= 0 ? { 'data-xsoffset' : this.props.xsOffset} : {}
    const xsTotalAttr = this.props.xsTotal >= 0 ? { 'data-xstotal' : this.props.xsTotal} : {}
    const smAttr = this.props.sm >= 0 ? { 'data-sm' : this.props.sm} : {}
    const smOffsetAttr = this.props.smOffset >= 0 ? { 'data-smoffset' : this.props.smOffset} : {}
    const smTotalAttr = this.props.smTotal >= 0 ? { 'data-smtotal' : this.props.smTotal} : {}
    const mdAttr = this.props.md >= 0 ? { 'data-md' : this.props.md} : {}
    const mdOffsetAttr = this.props.mdOffset >= 0 ? { 'data-mdoffset' : this.props.mdOffset} : {}
    const mdTotalAttr = this.props.mdTotal >= 0 ? { 'data-mdtotal' : this.props.mdTotal} : {}
    const lgAttr = this.props.lg >= 0 ? { 'data-lg' : this.props.lg} : {}
    const lgOffsetAttr = this.props.lgOffset >= 0 ? { 'data-lgoffset' : this.props.lgOffset} : {}
    const lgTotalAttr = this.props.lgTotal >= 0 ? { 'data-lgtotal' : this.props.lgTotal} : {}
    const xlAttr = this.props.xl >= 0 ? { 'data-xl' : this.props.xl} : {}
    const xlOffsetAttr = this.props.xlOffset >= 0 ? { 'data-xloffset' : this.props.xlOffset} : {}
    const xlTotalAttr = this.props.xlTotal >= 0 ? { 'data-xltotal' : this.props.xlTotal} : {}
    return(
      <div className={`col ${this.props.className}`}
        {...xsAttr}
        {...xsOffsetAttr}
        {...xsTotalAttr}
        {...smAttr}
        {...smOffsetAttr}
        {...smTotalAttr}
        {...mdAttr}
        {...mdOffsetAttr}
        {...mdTotalAttr}
        {...lgAttr}
        {...lgOffsetAttr}
        {...lgTotalAttr}
        {...xlAttr}
        {...xlOffsetAttr}
        {...xlTotalAttr}>
        {this.props.children}
      </div>
    ) 
  }
}

Col.defaultProps = {
  xs: null,
  xsOffset: null,
  xsTotal: null,
  sm: null,
  smOffset: null,
  smTotal: null,
  md: null,
  mdOffset: null,
  mdTotal: null,
  lg: null,
  lgOffset: null,
  lgTotal: null,
  xl: null,
  xlOffset: null,
  xlTotal: null,
  className: ''
}

Col.propTypes = {
  xs: PropTypes.number,
  xsOffset: PropTypes.number,
  xsTotal: PropTypes.number,
  sm: PropTypes.number,
  smOffset: PropTypes.number,
  smTotal: PropTypes.number,
  md: PropTypes.number,
  mdOffset: PropTypes.number,
  mdTotal: PropTypes.number,
  lg: PropTypes.number,
  lgOffset: PropTypes.number,
  lgTotal: PropTypes.number,
  xl: PropTypes.number,
  xlOffset: PropTypes.number,
  xlTotal: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.any.isRequired
}

export default Col