import {useStaticQuery, graphql} from 'gatsby'

export const useMessages = (lang = 'es') => {
  const data = useStaticQuery(
    graphql`
      query getMessagesQuery {
        es: file(sourceInstanceName: {eq: "languages"}, name: {eq: "es"}) {
          childJsonFile {
            messages {
              key
              value
            }
          }
        }
        eu: file(sourceInstanceName: {eq: "languages"}, name: {eq: "eu"}) {
          childJsonFile {
            messages {
              key
              value
            }
          }
        }
        en: file(sourceInstanceName: {eq: "languages"}, name: {eq: "en"}) {
          childJsonFile {
            messages {
              key
              value
            }
          }
        }

      }
    `
  );

  lang = ['es','eu','en'].indexOf(lang) !== -1 ? lang : 'es';

  return data[lang].childJsonFile.messages;
}