import React from 'react'
import CookieConsent from "react-cookie-consent";
import './custom-cookie-consent.scss'
import {t} from "../../utils/utils"
import { useMessages } from '../../hooks/use-messages'



const CustomCoookieConsent = ({lang = 'es'}) => {
  const messages = useMessages(lang);
  return (
    <CookieConsent
      buttonText="Si"
      style={{ background: "#ff0", color: "#000", alignItems: "flex-start" }}
      buttonStyle={{
        backgroundColor: "#fff",
        padding: "15px 60px",
        borderRadius: "45px",
        border: "3px solid #000",
        textTransfrom: "uppercase",
        margin: "30px 0 0"
      }}>
      <p className="featured">{t('cookie-consent-title', messages)}</p>
      <p>{t('cookie-consent-text', messages)} <a target="_blank" href="/politica-de-cookies">{t('cookie-consent-link', messages)}</a></p>
    </CookieConsent>
  )
}

export default CustomCoookieConsent;